import React from "react"
import { Box, Typography, Container, styled, Stack, Grid } from "@mui/material"
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined"
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined"
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined"
import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined"
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined"
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined"
import LoyaltyOutlinedIcon from "@mui/icons-material/LoyaltyOutlined"
import Diversity2OutlinedIcon from "@mui/icons-material/Diversity2Outlined"

import Title from "../../components/Title"
import Footer from "../../components/Footer"
import ImgRecruitmentService from "../../resources/recruitment-service.jpg"

const ListBox = styled(Box)({
  borderRadius: "15px",
  borderWidth: "1px",
  borderColor: "#000000",
  boxShadow: "0px 0px 3px #165EE824",
  display: "flex",
  flexDirection: "column",
  "&:hover": {
    boxShadow: "0px 0px 12px #165EE824",
    cursor: "pointer",
  },
})

export default () => {
  return (
    <div className="App">
      <img
        src={ImgRecruitmentService}
        width="100%"
        height="450"
        style={{ objectFit: "cover" }}
      />
      <Title
        title="RECRUITMENT SERVICES"
        subTitle="Are you looking for Talent?"
      />

      <Container
        maxWidth="xl"
        display="flex"
        justifyContent="center"
        sx={{ marginTop: 2 }}
      >
        <Box mb={3}>
          <Typography variant="body2">
            Savanna HR is a team of recruiters and headhunters who specialise in
            various sectors. They will take care of end to end recruitment for
            all your hiring needs.
          </Typography>
          <Typography variant="body2">
            Savanna HR has completed 10 years connecting talent to
            opportunities. We specialise in finding perfect fitment candidates
            as per your requirements in minimum Turn around Time. We provide
            recruitment services to new age StartUp and Big size organisations
            with same dedications and to the utmost satisfaction of clients.{" "}
          </Typography>
          <Typography variant="body2">
            Our clients vouch for your quality and 90% of our current business
            comes from referrals and repeat clients. We are a preferred
            recruitment partner for all our clients.
          </Typography>
          <Box py={3}>
            <Typography
              variant="h5"
              align="center"
              sx={{
                fontSize: "21px",
                color: "#091188",
                fontWeight: "600",
                marginTop: 3,
              }}
            >
              Our Technology Recruitment Expertise Includes
            </Typography>
            <Typography align="center" variant="body2">
              We focus on recruiting for the following sectors.
            </Typography>
          </Box>
          <Stack flexDirection="row" gap={2} mb={2} mt={2} flexWrap="wrap">
            <ListBox
              height={200}
              width={200}
              justifyContent="center"
              alignItems="center"
            >
              <AnalyticsOutlinedIcon fontSize="large" />
              <Typography variant="body2" align="center">
                Analytics
              </Typography>
            </ListBox>
            <ListBox
              height={200}
              width={200}
              justifyContent="center"
              alignItems="center"
            >
              <AccountBalanceWalletOutlinedIcon fontSize="large" />
              <Typography variant="body2" align="center">
                Finance and account
              </Typography>
            </ListBox>
            <ListBox
              height={200}
              width={200}
              justifyContent="center"
              alignItems="center"
            >
              <AccountBalanceOutlinedIcon fontSize="large" />
              <Typography variant="body2" align="center">
                Banking and Financial
              </Typography>
            </ListBox>
            <ListBox
              height={200}
              width={200}
              justifyContent="center"
              alignItems="center"
            >
              <ComputerOutlinedIcon fontSize="large" />
              <Typography variant="body2" align="center">
                Information technology
              </Typography>
            </ListBox>
            <ListBox
              height={200}
              width={200}
              justifyContent="center"
              alignItems="center"
            >
              <PlayCircleFilledWhiteOutlinedIcon fontSize="large" />
              <Typography variant="body2" align="center">
                Product based startups
              </Typography>
            </ListBox>
            <ListBox
              height={200}
              width={200}
              justifyContent="center"
              alignItems="center"
            >
              <StorefrontOutlinedIcon fontSize="large" />
              <Typography variant="body2" align="center">
                Marketing
              </Typography>
            </ListBox>
            <ListBox
              height={200}
              width={200}
              justifyContent="center"
              alignItems="center"
            >
              <LoyaltyOutlinedIcon fontSize="large" />
              <Typography variant="body2" align="center">
                Sales
              </Typography>
            </ListBox>
            <ListBox
              height={200}
              width={200}
              justifyContent="center"
              alignItems="center"
            >
              <Diversity2OutlinedIcon fontSize="large" />
              <Typography variant="body2" align="center">
                Social sectors
              </Typography>
            </ListBox>
          </Stack>
          <Box py={3}>
            <Typography
              variant="h5"
              align="center"
              sx={{
                fontSize: "21px",
                color: "#091188",
                fontWeight: "600",
                marginTop: 3,
              }}
            >
              Why Hiring Technical Experts?
            </Typography>
            <Typography align="center" variant="body2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
          </Grid>
          <Box py={3}>
            <Typography
              variant="h5"
              align="center"
              sx={{
                fontSize: "21px",
                color: "#091188",
                fontWeight: "600",
                marginTop: 3,
              }}
            >
              Why Choose Savanna HR for Technical Recruitment
            </Typography>
            <Typography align="center" variant="body2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </div>
  )
}
